// Styles for TabComponentContainer go here.
import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';

export const StyledTabComponentContainer = styled.div`
  ${rtl`
    // Overriding Common component styles
    .column-container {
      &-tabs {
        .tab-wrapper {
          box-shadow: none;
        }
        .tab-desc {
          padding: ${toRem(8)} 0;
        }
      }
    }
  `}
`;
